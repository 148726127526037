// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-recover-tsx": () => import("./../../../src/pages/account/recover.tsx" /* webpackChunkName: "component---src-pages-account-recover-tsx" */),
  "component---src-pages-account-request-account-tsx": () => import("./../../../src/pages/account/request-account.tsx" /* webpackChunkName: "component---src-pages-account-request-account-tsx" */),
  "component---src-pages-account-resetpassword-tsx": () => import("./../../../src/pages/account/resetpassword.tsx" /* webpackChunkName: "component---src-pages-account-resetpassword-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cancel-booking-tsx": () => import("./../../../src/pages/cancel-booking.tsx" /* webpackChunkName: "component---src-pages-cancel-booking-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-allbooking-index-tsx": () => import("./../../../src/pages/dashboard/allbooking/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-allbooking-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-managebooking-index-tsx": () => import("./../../../src/pages/dashboard/managebooking/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-managebooking-index-tsx" */),
  "component---src-pages-dashboard-modifiedbooking-index-tsx": () => import("./../../../src/pages/dashboard/modifiedbooking/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-modifiedbooking-index-tsx" */),
  "component---src-pages-dashboard-search-index-tsx": () => import("./../../../src/pages/dashboard/search/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-search-index-tsx" */),
  "component---src-pages-dashboard-settings-index-tsx": () => import("./../../../src/pages/dashboard/settings/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-settings-index-tsx" */),
  "component---src-pages-dashboard-todaybooking-index-tsx": () => import("./../../../src/pages/dashboard/todaybooking/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-todaybooking-index-tsx" */),
  "component---src-pages-dashboard-tomorrowbooking-index-tsx": () => import("./../../../src/pages/dashboard/tomorrowbooking/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-tomorrowbooking-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-shop-page-shop-page-tsx": () => import("./../../../src/templates/ShopPage/ShopPage.tsx" /* webpackChunkName: "component---src-templates-shop-page-shop-page-tsx" */)
}

