import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAppDispatch, useAppSelector } from "src/store/hooks"
import {
  setCustomerInfo,
  setIsValidCustomerInfo,
} from "src/store/shop/bookingSlice"
import { IInfoUserProps } from "src/store/shop/shop.types"

import { TextFieldSt, TypographySt } from "./StepComponents.styles"
import { WrapColSt } from "../ShopPage.styles"
import { useI18next } from "gatsby-plugin-react-i18next"
import { getSchema } from "./utils"

const InfoUser = ({ handleNext }: { handleNext: () => void }) => {
  const { t } = useI18next()
  const dispatch = useAppDispatch()
  const { firstName, lastName, phone, email, require, isValidCustomerInfo } =
    useAppSelector(state => state.booking)

  const schema = getSchema(t)

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<IInfoUserProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      lastName,
      firstName,
      email,
      phone,
      require,
    },
  })
  const dirtyRequireFields = Object.keys(dirtyFields).filter(
    key =>
      key === "email" ||
      key === "phone" ||
      key === "lastName" ||
      key === "firstName"
  ).length
  useEffect(() => {
    // Start checking when all required fields are dirty
    // Or case  User back fom Preview to UserInfo then Edit required Field
    if (dirtyRequireFields >= 3 || (isValid && dirtyRequireFields >= 1)) {
      handleSubmit(onSubmit)()
    }

    if (isValid && !isValidCustomerInfo) {
      dispatch(setIsValidCustomerInfo(true))
    }

    if (isValidCustomerInfo && !isValid) {
      dispatch(setIsValidCustomerInfo(false))
    }
  }, [
    watch("email"),
    watch("firstName"),
    watch("lastName"),
    watch("phone"),
    watch("require"),
    isValid,
  ])

  const onSubmit = (data: IInfoUserProps) => {
    dispatch(setCustomerInfo(data))
  }

  const handleSubmitByKeyboard = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit)()
      if (isValid) handleNext()
    }
  }
  return (
    <WrapColSt>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <TextFieldSt
          {...register("firstName")}
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
          variant="filled"
          placeholder="Vorname"
          label="Vorname*"
          autoComplete="off"
          onKeyDown={handleSubmitByKeyboard}
          onChange={e =>
            setValue("firstName", e.target.value, { shouldDirty: true })
          }
        />

        <TextFieldSt
          {...register("lastName")}
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
          variant="filled"
          placeholder="Nachname"
          label="Nachname*"
          // inputProps={{}}
          autoComplete="off"
          onKeyDown={handleSubmitByKeyboard}
          onChange={e =>
            setValue("lastName", e.target.value, { shouldDirty: true })
          }
        />

        <TextFieldSt
          {...register("email")}
          error={!!errors.email}
          helperText={errors?.email?.message}
          variant="filled"
          placeholder="johndoe@mail.com"
          label="E-Mail*"
          autoComplete="off"
          onKeyDown={handleSubmitByKeyboard}
          onChange={e =>
            setValue("email", e.target.value, { shouldDirty: true })
          }
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            const { onChange: onChangeCustom } = field
            return (
              <TextFieldSt
                autoComplete="off"
                {...field}
                onChange={e => {
                  const value = e.target.value
                  if (value === "") {
                    onChangeCustom("")
                  }
                  const num =
                    !/^\d+$/.test(value) && Number.isNaN(parseFloat(value))
                      ? null
                      : value
                  if (num) onChangeCustom(num)
                }}
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                type="tel"
                variant="filled"
                placeholder="01723567890"
                label="Telefonnummer*"
                onKeyDown={handleSubmitByKeyboard}
              />
            )
          }}
        />

        <TextFieldSt
          {...register("require")}
          label="Besondere Wünsche hinzufügen"
          multiline
          rows={4}
          placeholder="Sonderwünsche eingeben (ohne Gewähr)"
          variant="filled"
          onKeyDown={handleSubmitByKeyboard}
        />

        <input
          autoComplete="off"
          type="hidden"
          style={{
            display: "none",
          }}
        />
        <TypographySt>
          Alle Felder, die mit einem Sternchen (*) gekennzeichnet sind, müssen
          bei der Anmeldung ausgefüllt werden.
        </TypographySt>
      </form>
    </WrapColSt>
  )
}

export default InfoUser
